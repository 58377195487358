// vue.config.js

/**
 * @type {import('@vue/cli-service').ProjectOptions}
 */
module.exports = {
    publicPath: process.env.VUE_APP_ENV === 'staging'
        ? '/edugest_/'
        : '/'
}
//publicPath: '/', // edugest_ se va caricato su dev.bradipon.it
