import Vue from "vue";
import Router from "vue-router";
import {HomepageByRole} from "@/helpers/HomepageByRole";
import {ParseUrl} from "@/helpers/ParseUrl";
const vueConfig = require("../vue.config.js");
import {store} from "@/components/store.js";

Vue.use(Router);

const router = new Router({
    //mode: "history",
    mode: "hash",
    base: vueConfig.publicPath, // vedi vue.config.js
    routes: [
        {
            path: "/login",
            name: "login",
            component: () => import("./components/auth/Login"),
            meta: {
                requiresAuth: false,
            }
        },
        {
            path: "/register",
            name: "register",
            component: () => import("./components/auth/Register"),
            meta: {
                requiresAuth: false,
            }
        },
        {
            path: "/denied",
            name: "denied",
            component: () => import("./components/auth/Denied"),
            meta: {
                requiresAuth: false,
            }
        },
        {
            path: "/",
            name: "home",
            component: () => import("./components/layout/Home"),
            meta: {
                requiresAuth: true,
                roles: ['admin','owner','teacher','instructor','student']
            }
        },
        {
            path: "/teachers",
            name: "teachers-index",
            component: () => import("./components/teacher/TeacherIndex"),
            meta: {
                requiresAuth: true,
                roles: ['admin','owner','teacher','instructor']
            }
        },
        {
            path: "/instructors",
            name: "instructors-index",
            component: () => import("./components/instructor/InstructorIndex"),
            meta: {
                requiresAuth: true,
                roles: ['admin','owner','teacher','instructor']
            }
        },
        {
            path: "/students",
            name: "students-index",
            component: () => import("./components/student/StudentIndex"),
            meta: {
                requiresAuth: true,
                roles: ['admin','owner','teacher','instructor']
            }
        },
        {
            path: "/obtained-licenses",
            name: "obtained-licenses",
            component: () => import("./components/obtained_license/ObtainedLicenseIndex"),
            meta: {
                requiresAuth: true,
                roles: ['admin','owner']
            }
        },
        {
            path: "/school",
            name: "schools-view",
            component: () => import("./components/school/SchoolView"),
            meta: {
                requiresAuth: true,
                roles: ['admin','owner']
            }
        },
        {
            path: "/schools",
            name: "schools-index",
            component: () => import("./components/school/SchoolIndex"),
            meta: {
                requiresAuth: true,
                roles: ['admin']
            }
        },
        {
            path: "/instructor-availabilities",
            name: "instructor-availabilities",
            component: () => import("./components/instructor_availability/InstructorAvailabilityIndex"),
            meta: {
                requiresAuth: true,
                roles: ['instructor'],
            }
        },
        {
            path: "/bookings",
            name: "bookings-index",
            component: () => import("./components/booking/BookingIndex"),
            meta: {
                requiresAuth: true,
                roles: ['admin','owner','instructor', 'student']
            }
        },
        {
            path: "/bookings-simulator",
            name: "bookings-simulator-index",
            component: () => import("./components/booking/BookingSimulatorIndex"),
            meta: {
                requiresAuth: true,
                roles: ['admin','owner','instructor', 'student']
            }
        },
        {
            path: "/bookings-student",
            name: "bookings-student-index",
            component: () => import("./components/booking/BookingStudentIndex"),
            meta: {
                requiresAuth: true,
                roles: ['student']
            }
        },
        {
            path: "/bookings-simulator-student",
            name: "bookings-simulator-student-index",
            component: () => import("./components/booking/BookingSimulatorStudentIndex"),
            meta: {
                requiresAuth: true,
                roles: ['student']
            }
        },
        {
            path: "/courses",
            name: "courses-index",
            component: () => import("./components/course/CourseIndex"),
            meta: {
                requiresAuth: true,
                roles: ['admin','owner','teacher']
            }
        },
        {
            path: "/classrooms",
            name: "classrooms-index",
            component: () => import("./components/classroom/ClassroomIndex"),
            meta: {
                requiresAuth: true,
                roles: ['admin','owner','teacher']
            }
        },
        {
            path: "/vehicles",
            name: "vehicles-index",
            component: () => import("./components/vehicle/VehicleIndex"),
            meta: {
                requiresAuth: true,
                roles: ['admin','owner']
            }
        },
        {
            path: "/practices",
            name: "practices-index",
            component: () => import("./components/practice/PracticeIndex"),
            meta: {
                requiresAuth: true,
                roles: ['admin','owner','instructor']
            }
        },
        {
            path: "/available-times",
            name: "available-times-index",
            component: () => import("./components/available_time/AvailableTimeIndex"),
            meta: {
                requiresAuth: true,
                roles: ['admin','owner']
            }
        },
        {
            path: "/driving-licenses",
            name: "driving-licenses-index",
            component: () => import("./components/driving_license/DrivingLicenseIndex"),
            meta: {
                requiresAuth: true,
                roles: ['admin']
            }
        },
        {
            path: "/programs",
            name: "programs-index",
            component: () => import("./components/program/ProgramIndex"),
            meta: {
                requiresAuth: true,
                roles: ['admin']
            }
        },
        {
            path: "/product-license-template",
            name: "product-license-template-index",
            component: () => import("./components/product_license_template/ProductLicenseTemplateIndex"),
            meta: {
                requiresAuth: true,
                roles: ['admin']
            }
        },
        {
            path: "/product-licenses",
            name: "product-licenses",
            component: () => import("./components/product_license/ProductLicenseIndex"),
            meta: {
                requiresAuth: true,
                roles: ['admin','owner']
            }
        },
        {
            path: "/product-license-extend/:productLicenseCode",
            name: "product-licenses-extend",
            component: () => import("./components/product_license/ProductLicenseExtend"),
            meta: {
                requiresAuth: true,
                roles: ['owner','instructor','teacher','student']
            }
        },
        {
            path: "/products",
            name: "products",
            component: () => import("./components/product_license/ProductLicenseProfileIndex"),
            meta: {
                requiresAuth: true,
                roles: ['owner','instructor','teacher','student']
            }
        },
        {
            path: "/activate/:code?",
            name: "activate",
            component: () => import("./components/product_license/ProductLicenseActivate"),
            meta: {
                requiresAuth: true,
                roles: ['owner','instructor','teacher','student']
            }
        },
        {
            path: "/activate-home/",
            name: "activate-home",
            component: () => import("./components/product_license/ProductLicenseActivateHome"),
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/product-denied/",
            name: "product-denied",
            component: () => import("./components/product_license/ProductLicenseNotValid.vue"),
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/purchases",
            name: "purchases-index",
            component: () => import("./components/purchase/PurchaseIndex"),
            meta: {
                requiresAuth: true,
                roles: ['admin','owner']
            }
        },
        {
            path: "/attendance-register",
            name: "attendance-register",
            component: () => import("./components/attendance/AttendanceRegister"),
            meta: {
                requiresAuth: true,
                roles: ['student']
            }
        },
        {
            path: "/simulator-access",
            name: "simulator-access",
            component: () => import("./components/simulator_access/SimulatorAccess"),
            meta: {
                requiresAuth: true,
                roles: ['student','instructor','teacher','owner']
            }
        },
        {
            path: "/scanqr",
            name: "scanqr",
            component: () => import("./components/layout/ScanQr"),
            meta: {
                requiresAuth: true,
                roles: ['student','instructor','teacher','owner']
            }
        },
        {
            path: "/activity-logs",
            name: "activity-logs",
            component: () => import("./components/activity_log/ActivityLogIndex"),
            meta: {
                requiresAuth: true,
                roles: ['student']
            }
        },
        {
            path: "/booklet/:studentId?",
            name: "booklet",
            props: true,
            component: () => import("./components/booklet/BookletPrint"),
            meta: {
                requiresAuth: true,
                roles: ['student','owner','admin']
            }
        },
        {
            path: "/attendances",
            name: "attendance-index",
            component: () => import("./components/attendance/AttendanceIndex"),
            meta: {
                requiresAuth: true,
                roles: ['student']
            }
        },
        {
            path: "/available-times-student",
            name: "available-times-student-index",
            component: () => import("./components/available_time/AvailableTimeStudentIndex"),
            meta: {
                requiresAuth: true,
                roles: ['student']
            }
        },
        {
            path: "/purchase-available-times",
            name: "purchase-available-times",
            component: () => import("./components/purchase/PurchaseAvailableTime"),
            meta: {
                requiresAuth: true,
                roles: ['student']
            }
        },
        {
            path: "/purchase-success",
            name: "purchase-success",
            component: () => import("./components/purchase/PurchaseSuccess"),
            meta: {
                requiresAuth: true,
                roles: ['student']
            }
        },
        {
            path: "/purchase-error",
            name: "purchase-error",
            component: () => import("./components/purchase/PurchaseError"),
            meta: {
                requiresAuth: true,
                roles: ['student']
            }
        },
        {
            path: "/purchase-canceled",
            name: "purchase-canceled",
            component: () => import("./components/purchase/PurchaseCanceled"),
            meta: {
                requiresAuth: true,
                roles: ['student']
            }
        },
        {
            path: "/profile",
            name: "profile",
            component: () => import("./components/profile/ProfileView"),
            meta: {
                requiresAuth: true,
                roles: ['student','teacher','owner','instructor','admin']
            }
        },
        {
            path: "/confirm-profile/:token/:keepSession?",
            name: "confirm-profile",
            props: true,
            component: () => import("./components/profile/ConfirmProfile"),
            meta: {
                requiresAuth: false,
            }
        },
        {
            path: "/confirm-profile-school/:token",
            name: "confirm-profile-school",
            props: true,
            component: () => import("./components/profile/ConfirmProfileSchool"),
            meta: {
                requiresAuth: false,
            }
        },
        {
            path: '/logout',
            component: () => import('./components/auth/Logout')
        },
        {
            path: '/reset-password',
            component: () => import('./components/auth/ResetPassword'),
            meta: {
                requiresAuth: false,
            }
        },
        {
            path: '/reset-password-ok',
            component: () => import('./components/auth/ResetPasswordOk'),
            meta: {
                requiresAuth: false,
            }
        },
        {
            path: '/forgot-password',
            component: () => import('./components/auth/ForgotPassword'),
            meta: {
                requiresAuth: false,
            }
        },
        {
            path: '/forgot-password-ok',
            component: () => import('./components/auth/ForgotPasswordOk'),
            meta: {
                requiresAuth: false,
            }
        },
        {
            path: '/stats',
            name: 'stats',
            component: () => import('./components/stats/StatsView'),
            meta: {
                requiresAuth: true,
                roles: ['admin']
            }
        },
        {
            path: '/404',
            component: () => import("./components/layout/NotFound"),
        },
        {
            path: '*',
            redirect: '/404'
        },

    ]
});
// Role guard
router.beforeEach((to, from, next) => {
    const authUser = JSON.parse(window.localStorage.getItem('authUser'));
    const parsedUri = ParseUrl();
    // redirect to passed uri. useful for link like /activate
    if (parsedUri.redirectUri != '/' && authUser) window.location.href = vueConfig.publicPath + parsedUri.query + '#' + parsedUri.redirectUri;
    // check user permission
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if true, redirect to main page.
        if (authUser && authUser.token) {
            if (!to.meta.roles) next();
            else if (to.meta.roles.some(requestedRole => authUser.data.roles.indexOf(requestedRole) >= 0)) next();
            else next('denied');
        } else next('login');
    } else if (authUser && authUser.token && to.name == 'login') next(HomepageByRole(authUser.data.roles[0])); // redirect by role if logged
    else next();
})

export default router;
