<template>
    <div id="header">

            <div></div>
            <div class="text-center">
                <img src="../../assets/logo-horiz.png" class="logo">
            </div>
            <div class="text-right" style=" font-size: 24px;">
                <router-link class="nav-link qrtop" to="/scanqr" style="padding-top: 10px" ontouchstart="background-color: #eeeeee;">
                    <b-icon icon="upc-scan"/>
                </router-link>
            </div>
            <!--
            <div class="col-2 text-center" style="padding: 5px 1px 0px 0px">
                <router-link class="nav-link" to="/profile">
                    <img src="../../assets/user-Icon.jpg" class="rounded-circle" style="width: 30px">
                </router-link>
            </div>
            -->
    </div>
</template>

<script>
export default {
    name: "HeaderMobile"
}
</script>

<style scoped>
#header {
    position: fixed;
    display: flex;
    flex-direction: row;

    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    padding: 5px 8px;
    background-color: #F2F4F4;
    background-color: #FFFFFF;
    border-bottom: 1px solid #C7CFD6;
}
.dark #header {
    background-color: #1D1F21;
}
#header div {
    flex-grow: 2; /* default 0 */
    min-width: 40px;
}
#header .logo {
    width: auto;
    height: auto;
    max-height: 50px;
}
a.qrtop {
    display: inline-block;
}
a.qrtop:active {
    background-color: #eeeeee;
}
</style>
