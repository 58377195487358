import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export var store = new Vuex.Store({
    state: {
        isLoggedIn: !!localStorage.getItem('authUser'),
        user: (JSON.parse(localStorage.getItem('authUser')) !== null) ? JSON.parse(localStorage.getItem('authUser')).data : null,
        token: (JSON.parse(localStorage.getItem('authUser')) !== null) ? JSON.parse(localStorage.getItem('authUser')).token : null,
        schoolId: (JSON.parse(localStorage.getItem('authUser')) !== null) ? JSON.parse(localStorage.getItem('authUser')).data.schoolId : null,
        schoolName: (JSON.parse(localStorage.getItem('authUser')) !== null) ? JSON.parse(localStorage.getItem('authUser')).data.schoolName : null,
        schools: (JSON.parse(localStorage.getItem('authUser')) !== null) ? JSON.parse(localStorage.getItem('authUser')).data.schools : [],
        loading: false,
        hideProgressBar: false,
        darkView: false,
        processEnv: process.env.VUE_APP_ENV
    },
});

document.addEventListener('onLoggedOut', function(event) {
    //console.log('onLoggedOut');
    window.localStorage.removeItem('authUser');
    store.state.isLoggedIn = false;
    store.state.user = null;
    store.state.token = null;
    store.state.schoolId = null;
    store.state.schoolName = null;
});
