import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import {store} from  './components/store'
import {ToggleButton} from 'vue-js-toggle-button'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueMeta from 'vue-meta'
import vClickOutside from 'v-click-outside'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-search-select/dist/VueSearchSelect.css'
import '@/assets/styles/app.css'
import '@/assets/styles/dark.css'
//
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuelidate)
Vue.use(VueMeta)
Vue.use(Vuex)
Vue.use(vClickOutside)
// component used globally
Vue.component('ToggleButton', ToggleButton)
// non mi ricordo a cosa serve!
Vue.config.productionTip = false;
// Queste funzioni dichiarate nel mixin vengono ereditate da tutti i componenti
Vue.mixin({
    methods: {
        // Check if user has the requested role
        hasRole: role => store.state.user.roles.includes(role)
    }
})
// Recursive function to count tree lenght
Array.prototype.treeLength = function() {
    let sum = 0;
    for (let count = 0; count < this.length; count ++) {
        sum += this[count].length ? this[count].treeLength() : 1;
    }
    return sum;
};

Vue.filter('toCurrency', function (value) {
    let formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR'
    });
    return formatter.format(value);
});

// Declare Vue
new Vue({
    el: '#app',
    store,
    router,
    validations: {},
    render: h => h(App),
}).$mount('#app')
