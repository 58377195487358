<template>
    <div>
        <div id="sidebarUnderLayer" v-if="showSidebar && isMobile" @click="closeSidebar()"></div>
        <div id="sidebar" :class="{ 'active': showSidebar }">
            <p class="logo text-center"> <img alt="logo" src="../../assets/logo-horiz.png" height="43"></p>
            <hr style="margin-bottom: 0">
            <switch-school/>
            <hr style="margin: 0">
            <div id="sidemenu">
                <!-- ADMIN -->
                <ul class="nav flex-column" v-if="hasRole('admin') && !this.$store.state.schoolId">
                    <li class="nav-item">
                        <router-link class="nav-link" to="/driving-licenses"><img src="../../assets/card.png"> Patenti</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/programs"><img src="../../assets/program.png"> Programmi</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/product-license-template"><img src="../../assets/license.png"> Licenze</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/schools"><img src="../../assets/school.png"> Scuole</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/stats"><img src="../../assets/stats.png" width="19"> Statistiche</router-link>
                    </li>
                </ul>
                <ul class="nav flex-column" v-if="hasRole('admin') && this.$store.state.schoolId > 0">
                    <li class="nav-item" style="padding-left:25px">
                        <b-button variant="warning" size="sm" @click="resetAdmin"><b-icon icon="power"/> Esci dalla scuola</b-button>
                    </li>
                    <li class="nav-separator">
                        <hr>
                    </li>
                </ul>

                <!-- OWNER -->
                <ul class="nav flex-column" v-if="hasRole('owner') || (hasRole('admin') && this.$store.state.schoolId > 0)">
                    <li class="nav-item">
                        <router-link class="nav-link" to="/school"><img src="../../assets/school.png"> Scuola</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/product-licenses"><img src="../../assets/license.png"> Licenze</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/obtained-licenses"><img src="../../assets/card.png"> Rinnovi</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/purchases"><img src="../../assets/program.png"> Acquisti</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/students"><img src="../../assets/student.png"> Allievi</router-link>
                    </li>
                    <li class="nav-separator">
                        <hr>
                        <span class="badge badge-warning" style="margin-left: 20px">Lezioni</span>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link"  to="/teachers"><img src="../../assets/teacher.png"> Insegnanti</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/courses"><img src="../../assets/classes.png"> Corsi</router-link>
                    </li>
                    <li class="nav-separator">
                        <hr>
                        <span class="badge badge-warning" style="margin-left: 20px">Guide</span>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/instructors"><img src="../../assets/teacher.png"> Istruttori</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/vehicles"><img src="../../assets/vehicle.png"> Mezzi</router-link>
                    </li>
                    <li class="nav-item">
                        <a><img src="../../assets/booking.png"> Prenotazioni <b-icon icon="caret-down-fill" font-scale="0.7" class="nav-item-caret"/></a>
                        <ul class="nav flex-column submenu">
                            <li class="nav-item">
                                <router-link class="nav-link" to="/bookings">con istruttore</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" to="/bookings-simulator">simulatore</router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/practices"><img src="../../assets/guide.png"> Guide</router-link>
                    </li>

                </ul>

                <!-- TEACHER + OWNER -->
                <ul class="nav flex-column" v-if="hasRole('teacher') && !hasRole('owner')">
                    <li class="nav-separator">
                        <span class="badge badge-warning" style="margin-left: 20px">Lezioni</span>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/courses"><img src="../../assets/classes.png"> Corsi</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/students"><img src="../../assets/student.png"> Allievi</router-link>
                    </li>

                </ul>

                <!-- INSTRUCTOR + OWNER -->
                <ul class="nav flex-column" v-if="hasRole('instructor') && !hasRole('owner')">
                    <li class="nav-separator">
                        <hr>
                        <span class="badge badge-warning" style="margin-left: 20px">Guide</span>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/instructor-availabilities" :instructor-id="$store.state.user.profileId"><img src="../../assets/booking.png"> Disponibilità</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/bookings"><img src="../../assets/booking.png"> Prenotazioni</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/practices"><img src="../../assets/guide.png"> Guide</router-link>
                    </li>
                </ul>

                <!-- STUDENT -->
                <ul class="nav flex-column" v-if="hasRole('student')">

                    <li class="nav-item">
                        <router-link class="nav-link" to="/products"><img src="../../assets/program.png"> Prodotti</router-link>
                    </li>

                    <li class="nav-item">
                        <a><img src="../../assets/booking.png"> Prenotazioni <b-icon icon="caret-down-fill" font-scale="0.7" class="nav-item-caret"/></a>
                        <ul class="nav flex-column submenu">
                            <li class="nav-item">
                                <router-link class="nav-link" to="/bookings-student">con istruttore</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" to="/bookings-simulator-student">simulatore</router-link>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <router-link class="nav-link" to="/available-times-student"><b-icon icon="clock-history"/> Monte ore</router-link>
                    </li>

                    <li class="nav-item">
                        <router-link class="nav-link" to="/activity-logs"><img src="../../assets/program.png"> Attività svolte</router-link>
                    </li>

                    <li class="nav-item">
                        <router-link class="nav-link" to="/attendances"><img src="../../assets/program.png"> Presenze</router-link>
                    </li>
                </ul>

                <ul class="nav flex-column">
                    <li class="nav-separator">
                        <hr>
                    </li>
                    <li class="nav-item">

                    </li>
                </ul>

                <!-- mobile -->
                <ul class="nav flex-column" v-if="isMobile">
                    <li class="nav-item">
                        <router-link class="nav-link" to="/profile">
                            <img src="../../assets/user-Icon.jpg" class="rounded-circle" style="width: 25px"> Profilo
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/logout">
                            <span class="circle" style="margin-right: 15px;"><b-icon icon="box-arrow-left"/></span> Esci
                        </router-link>
                    </li>
                    <li class="nav-separator">
                        <hr>
                    </li>
                </ul>

                <p><br></p>
                <p><br></p>

            </div>

            <!--
                        <a href="#" id="sidebarCollapseButton" :class="{ 'active': showSidebar }" v-on:click="toggleSidebar()">
                            <img alt="menu button" src="../../assets/menu-button-2.png">
                        </a>
            -->
            <button href="#" id="sidebarCollapseButton2" :class="{ 'active': showSidebar }" v-on:click="toggleSidebar()"></button>
        </div>
    </div>
</template>

<script>

import SwitchSchool from "@/components/layout/SwitchSchool";

export default {
    name: "Sidebar",
    components: {SwitchSchool},
    data() {
        return {
            showSidebar: true,
            closeAfterClick: false,
            activeItem: ''
        }
    },
    props: ['isMobile'],
    methods: {
        clickOutside(event) {
            if (this.isMobile) this.closeSidebar();
        },
        toggleSidebar() {
            this.showSidebar = !this.showSidebar;
            this.$emit('toggle-sidebar', this.showSidebar);
        },
        closeSidebar() {
            //console.log('close');
            this.showSidebar = false;
            this.$emit('toggle-sidebar', this.showSidebar);
        },
        resetAdmin() {
            let authUser = JSON.parse(window.localStorage.getItem('authUser'));
            authUser.data.schoolId = null;
            window.localStorage.setItem('authUser', JSON.stringify(authUser));
            this.$store.state.schoolId = null;
            this.$router.push('schools');
        },
        setCloseAfterClick() {
            this.closeAfterClick = (window.innerWidth <= 1024);
            this.showSidebar = (window.innerWidth > 1024);
            this.$emit('toggle-sidebar', this.showSidebar);
        }
    },
    mounted() {
        let links = document.getElementsByClassName('nav-link');
        let self = this;
        for (var i = 0; i < links.length; i++) {
            links[i].addEventListener("click", function() {
                if (self.closeAfterClick) {
                    self.showSidebar = false;
                    self.$emit('toggle-sidebar', this.showSidebar);
                }
            });
        }

        window.addEventListener('resize', this.setCloseAfterClick);
        this.setCloseAfterClick();
    }
}
</script>

<style >

#sidebarUnderLayer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(138, 138, 138, .35);
    z-index: 1000;
}
#sidebar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    width: auto;
    min-width: 210px;
    max-width: 210px;
    margin-left: -210px;
    min-height: 100vh;

    -webkit-transition: all 0.3s 0s ease;
    -moz-transition: all 0.3s 0s ease;
    -o-transition: all 0.3s 0s ease;
    transition: all 0.3s 0s ease;

    padding: 15px 0px;

    -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.25);

    background-color: #fff;
}

#sidebar.active {
    margin-left: 0px;
}

#sidebar .school-name {
    padding: 2px 0px;
    margin: 0;
    background-color: #f9f9f9;
    text-align: center;
}

#sidemenu {
    height: 90vh;
    padding-top: 10px;
    overflow-x: hidden;
    overflow-y: auto;
}

#sidebarCollapseButton {
    position: fixed;
    top: 13px;
    left: 210px;
    margin-left: -203px;
    transition: all 0.3s;
    z-index: 10;
    border-radius: 50%;
}
#sidebarCollapseButton img {
    border-radius: 50%;
    -webkit-box-shadow: 4px 2px 15px 0 rgba(89, 90, 91, 0.43);
    -moz-box-shadow: 4px 2px 15px 0 rgba(89, 90, 91, 0.43);
    box-shadow: 4px 2px 15px 0 rgba(89, 90, 91, 0.43);
}
#sidebarCollapseButton:active img {
    border-radius: 50%;

    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

#sidebarCollapseButton.active {
    margin-left: 7px;

}


#sidebarCollapseButton2 {
    display: block;
    width: 43px;
    height: 43px;
    position: fixed;
    top: 13px;
    left: 210px;
    margin-left: -203px;
    transition: margin 0.3s;
    z-index: 10;
    border-radius: 50%;
    border: none;
    background: #fff url("../../assets/three-dots-vertical.png") center center no-repeat;
    -webkit-box-shadow: 4px 2px 15px 0 rgba(89, 90, 91, 0.43);
    -moz-box-shadow: 4px 2px 15px 0 rgba(89, 90, 91, 0.43);
    box-shadow: 4px 2px 15px 0 rgba(89, 90, 91, 0.43);
}
#sidebarCollapseButton2 img {

}
#sidebarCollapseButton2:active {

    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

#sidebarCollapseButton2.active {
    margin-left: 7px;
}


#sidebar li.nav-item a {
    /* color:#C7CFD6!important; */
    display: block;
    font-size: 16px;
    padding: 10px 28px;
    width: 100%;
    background-color: transparent;
    transition: all 0.3s;
}
#sidebar li.nav-item a img {
    margin-right: 10px;
}
#sidebar li.nav-item a:hover, #sidebar li.nav-item a.router-link-active {
    background-color: #F2F4F4;
    text-decoration: none;
}

#sidebar .submenu {
    background-color: rgba(223, 232, 248, 0.1);
    /*background-color: #f6f8f8;*/
    -webkit-box-shadow: inset 0px 0px 1px 0px rgba(0,0,0,0.32);
    box-shadow: inset 0px 0px 1px 0px rgba(0,0,0,0.32);
}
#sidebar .submenu li.nav-item a {
    padding: 5px 28px;
    padding-left: 58px;
    font-size: 1em;
}

#sidebar .nav-item-caret {
    float: right;
    margin-top: 7px;
    opacity: .7;
}

@media only screen and (max-width: 600px) {
    #sidebarCollapseButton2 {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        -webkit-transition: all 0.1s 0s ease;
        -moz-transition: all 0.1s 0s ease;
        -o-transition: all 0.1s 0s ease;
        transition: all 0.1s 0s ease;
    }
    #sidebar.active #sidebarCollapseButton2,#sidebar.active {
        -webkit-box-shadow: 4px 2px 15px 0 rgba(89, 90, 91, 0.43);
        -moz-box-shadow: 4px 2px 15px 0 rgba(89, 90, 91, 0.43);
        box-shadow: 4px 2px 15px 0 rgba(89, 90, 91, 0.43);
    }

    #sidebar {
        padding-top: 0;
        -webkit-transition: all 0.1s 0s ease;
        -moz-transition: all 0.1s 0s ease;
        -o-transition: all 0.1s 0s ease;
        transition: all 0.1s 0s ease;
    }

    #sidebar .school-name {
        padding: 10px 0px;

    }

    #sidebar .logo {
        display: none;
    }
    #sidebar li.nav-item a {
        padding: 12px 28px;
    }
    #sidebar li.nav-separator hr {
        margin: 12px 0;
    }
    #sidemenu {
        display: flex; flex-direction: column;

    }
}

</style>
