//
const vueConfig = require("../../vue.config.js");

export const ParseUrl = function() {
    let ret = {
        schoolId: null,
        redirectUri: '',
        query: '',
        queryParams: [] // URLSearchParams object
    }
    const uri = window.location.pathname.substr(vueConfig.publicPath.length, window.location.pathname.length);
    const uriComponents = uri.split('/').filter(n => n);
    if (!isNaN(uriComponents[0]) && uriComponents[0].length > 0) {
        ret.schoolId = uriComponents[0]
        uriComponents.shift();
    }
    //
    ret.redirectUri = '/' + uriComponents.join('/');
    //
    if (location.search !== undefined) {
        ret.query = location.search;
        ret.queryParams = new URLSearchParams(location.search);
    }
    return ret;
};
