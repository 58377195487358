//
export const HomepageByRole = role => {
    let uri = ''
    switch (role) {
        case 'admin':
            uri = '/schools';
            break;
        case 'owner':
            uri = '/school';
            break;
        case 'teacher':
            uri = '/courses';
            break;
        case 'instructor':
            uri = '/bookings';
            break;
        case 'student':
            uri = '/products';
            break;
        default:
            uri = '/denied';
            break;
    }
    return uri;
};
