<template>

    <div>
        <div class="usermenu" :class="{'usermenu-opened': showDropdown}"
             v-click-outside="closeDropdown"
             tabindex="0">
            <a href="#" class="no-decoration toggle-dropdown" @click="toggleDropdown">
                <img src="../../assets/user-Icon.jpg" class="rounded-circle" style="width: 30px">
                {{ this.$store.state.user.firstname + ' ' + this.$store.state.user.lastname }}
            </a>

            <div class="user-dropdown" v-if="showDropdown" tabindex="0">
                <b-nav vertical>
                    <b-nav-item @click="$router.push('/profile')"><div class="circle"><b-icon icon="person"/></div>  Profilo </b-nav-item>
                    <b-nav-item @click="$router.push('/logout')"><div class="circle"><b-icon icon="power"/></div> Esci</b-nav-item>
                </b-nav>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    name: "UserMenu",
    data() {
        return {
            showDropdown: false,
            darkView: null,
            html: '<img src="../../assets/user-Icon.jpg" class="rounded-circle" style="width: 30px">'
        }
    },
    methods: {
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },
        closeDropdown(event) {
            this.showDropdown = false;
        }
    },
    watch: {
        '$route' () {
            this.closeDropdown();
        }
    }
}
</script>

<style scoped>
    .usermenu {
        position: fixed;
        top: 23px;
        top: 18px;
        right: 25px;
        z-index: 100;


        display: inline-block;
        background-color: #fff;
        border-radius: 0.55rem!important;
        border-radius: 1rem!important;

        -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.25);
        -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.25);
        box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.25);
    }
    .usermenu:focus { outline: none; }
    .usermenu-opened {
        -webkit-box-shadow: 4px 2px 15px 0 rgba(89, 90, 91, 0.43);
        -moz-box-shadow: 4px 2px 15px 0 rgba(89, 90, 91, 0.43);
        box-shadow: 4px 2px 15px 0 rgba(89, 90, 91, 0.43);
    }
    .usermenu a.toggle-dropdown {
        display: block;
        padding: 7px 15px;
    }
    .usermenu img { margin-right: 12px}
    .usermenu a:hover {
        text-decoration: none;
    }
    .user-dropdown {
        margin-left: 0px;
        margin-right: 0px;
        text-align: left;
        margin-top: 5px;
        border-top: 1px solid #eee;
    }
    .user-dropdown li:hover {
        background-color: #eee;
    }
    .circle {
        display: inline-block;
        width: 30px;
        height: 30px;
        padding-top: 2px;
        border-radius: 50%;
        background-color: #e4eeef;
        margin-right: 10px;
        text-align: center;
        vertical-align: middle;
        color: #222e3c;
    }
</style>
