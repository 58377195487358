<template>
    <div v-if="view" class="progress-bar-top">
        <b-progress :value="progress" max="100" height="2px" :variant="variant"></b-progress>
    </div>

</template>

<script>
export default {
    name: "ProgressBar",
    data() {
        return {
            view: false,
            ticker: 500,
            variant: 'primary',
            progress: 0,
            step: 10,
            interval: null,
            timeout: null,
        }
    },
    methods: {
        start() {
            clearTimeout(this.timeout);
            this.view = true;
            this.progress = 0;
            let self = this;
            this.interval = setInterval(function () {
                if (self.progress < 90) {
                    self.progress += self.step;
                } else if (self.progress < 95) {
                    self.progress += (self.step / 2);
                }
            }, self.ticker);
        },
        done() {
            clearInterval(this.interval);
            this.progress = 100;
            let self = this;
            this.timeout = setTimeout(function () {
                self.view = false;
                self.progress = 0;
            }, self.ticker*1.5);

        },
        fail() {
            clearInterval(this.interval);
            this.progress = 100;
            let self = this;
            this.timeout = setTimeout(function () {
                self.view = false;
                self.progress = 0;
            }, self.ticker*1.2);
        }
    },
    mounted() {

    },
    beforeDestroy() {
        clearInterval(this.interval)
        this.view = false;
    }
}
</script>

<style scoped>
.progress-bar-top {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    z-index: 2001;
}
.progress-bar-top .progress {
    border-radius: 0;
    background-color: transparent;
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 2000;
}
</style>
