<template>
    <div id="app">
        <progress-bar ref="progressBar"/>
        <b-alert v-if="this.$store.state.isLoggedIn" id="general-alert" variant="danger" :show="httpErrorMsg != '' " dismissible fade>{{ httpErrorMsg }}</b-alert>
        <b-alert v-if="this.$store.state.isLoggedIn" id="message-alert" variant="success" :show="successMsg != '' " dismissible fade>{{ successMsg }}</b-alert>
        <print v-if="forPrint()"/>
        <admin v-else-if="this.$store.state.isLoggedIn"/>
        <front v-else/>
    </div>
</template>


<script>
import Admin from "@/components/layout/Admin";
import Front from "@/components/layout/Front";
import Print from "@/components/layout/Print";
import ProgressBar from "@/components/layout/ProgressBar";
import AuthDataService from "@/components/auth/AuthDataService";

export default {
    name: "app",
    components: {Front, Admin, Print, ProgressBar},
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'EduGest',
        // all titles will be injected into this template
        //titleTemplate: '%s | My Awesome Webapp'
        htmlAttrs: {
            lang: 'it',
        },
        meta: [
            { charset: 'utf-8' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1' },
            { name: 'apple-mobile-web-app-capable', content: 'yes' },
        ]
    },
    data() {
        return {
            httpErrorMsg: '',
            successMsg: '',
            keepAliveInterval: null
        }
    },
    methods: {
        setDarkView() {
            document.body.classList.add('dark')
        },
        setLightView() {
            document.body.classList.remove('dark');
        },
        setViewMode(isDark) {
            if (isDark) this.setDarkView();
            else this.setLightView()
        },
        forPrint() {
            return window.location.href.search('print=1') > -1;
        },
        hidePogressBar(event) {
            if (event.url.includes('keepalive')) return true;
            if (event.url.includes('stats/online')) return true;
            return false;
        }
    },
    mounted() {
        /*
        // commentato perché andava in conflitto con il router, infatti alcune pagine visualizzabili senza login
        // venivano bloccato da questo blocco di codice! Da capire bene.
        AuthDataService.me()
            .catch(e => {
                // redirect to login if not already logged in
                if (!e.response.data.auth) {
                    this.$store.state.isLoggedIn = false;
                    this.$router.push('/login');
                }
            });
        */

        let self = this;

        if (this.$store.state.isLoggedIn && !self.keepAliveInterval) self.keepAliveInterval = setInterval(() => {
            AuthDataService.keepAlive();
        }, 5000);

        document.addEventListener('onHttpError', function(event) {
            if(!self.$store.state.hideProgressBar) {
                self.$refs.progressBar.fail();
            }

            if(self.httpErrorMsg != '') {
                self.httpErrorMsg = '';
            }
            if(event.errno == 500) {
                setTimeout(function () {
                    self.httpErrorMsg = event.errno +' | '+ event.message;
                }, 200)
            }
        });
        document.addEventListener('onSuccessMsg', function(event) {
            self.successMsg = event.message;
        });
        document.addEventListener('onHttpRequest', function(event) {
            if(!self.$store.state.hideProgressBar && !self.hidePogressBar(event)) {
                self.$refs.progressBar.start()
            }
        });
        document.addEventListener('onHttpResponse', function(event) {
            if(!self.$store.state.hideProgressBar && !self.hidePogressBar(event)) {
                self.$refs.progressBar.done();
            }
        });
        document.addEventListener('onLoggedIn', function(event) {
            if (!self.keepAliveInterval) self.keepAliveInterval = setInterval(() => {
                AuthDataService.keepAlive();
            }, 5000);
        });
        document.addEventListener('onLoggedOut', function(event) {
            clearInterval(self.keepAliveInterval);
            self.keepAliveInterval = null;
            // redirect to login on logout
            self.$router.push('/login').catch(() => {});
            if(!self.$store.state.hideProgressBar) {
                self.$refs.progressBar.done();
            }
        });
        // Dark mode
        const darkView = window.matchMedia('(prefers-color-scheme: dark)').matches;
        this.setViewMode(darkView);
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
            this.setViewMode(e.matches);
        });
    }
};
</script>
