<template>
    <div>
        <div v-if="$store.state.schools.length > 0">
            <b-dropdown
                id="switch-school"
                variant="link"
                size="sm"
                block
                :text="$store.state.schoolName"
                class="m-2">
                <b-dropdown-item v-for="school in $store.state.schools"
                                 :key="school.id"
                                 @click="switchSchool(school.id)">{{ school.name }}</b-dropdown-item>
            </b-dropdown>
        </div>
        <p class="school-name" v-else>{{ $store.state.schoolName }}</p>
    </div>

</template>

<script>
import AuthDataService from "@/components/auth/AuthDataService";
import {store} from "@/components/store";

export default {
    name: "switch-school",
    data() {
        return {

        }
    },
    methods: {
        switchSchool(id) {
            AuthDataService.switchSchool(id)
                .then((response) => {
                    if (response.data.auth) {
                        let authUser = {};
                        authUser.data = response.data.user;
                        authUser.token = response.data.token;
                        window.localStorage.setItem('authUser', JSON.stringify(authUser));
                        this.$store.state.user = authUser.data;
                        this.$store.state.token = authUser.token;
                        this.$store.state.schoolId = authUser.data.schoolId;
                        this.$store.state.schoolName = authUser.data.schoolName;
                        this.$store.state.schools = authUser.data.schools;
                        //this.$router.go(this.$router.currentRoute)
                        window.location.reload();
                    }
                })
                .catch((errors) => {
                    alert(errors.response.data.message);
                    console.log("Cannot log in:" + errors.response.data.errorCode);
                });
        }
    },
    mounted() {

    }
}
</script>
