<template>
    <div class="wrapper">

        <!-- Logo per mobile -->
        <header-mobile v-if="isMobile"/>

        <!-- User menu per desktop -->
        <user-menu v-if="!isMobile"/>

        <!-- Sidebar per desktop -->
        <sidebar :is-mobile="isMobile"
                 @toggle-sidebar="setFullView($event)"/>

        <!-- Menubar per mobile -->


        <!-- Page Content -->
        <div id="content" :class="{ 'fullview': (fullview) }">
            <div class="container-fluid">
                <router-view/>
                <div class="text-right" style="margin-right: 15px">
                    <br>
                    <br>
                    <version/>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Sidebar from "@/components/layout/Sidebar";
import UserMenu from "@/components/layout/UserMenu";
import HeaderMobile from "@/components/layout/HeaderMobile";
import Version from "@/components/layout/Version";
import '@/assets/styles/admin.css'

export default {
    name: "Admin",
    data() {
        return {
            isMobile: false,
            fullview: false,
            toggleSidebarevent: null
        }
    },
    components: { Sidebar, UserMenu, HeaderMobile, Version },
    methods: {
        setIsMobile() {
            this.isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || window.innerWidth <= 650;
        },
        setFullView(showSidebar) {
            this.fullview = !showSidebar;
            document.dispatchEvent(this.toggleSidebarevent);
        }
    },
    mounted() {
        window.addEventListener('resize', this.setIsMobile);
        this.setIsMobile()
    },
    created() {
        this.toggleSidebarevent = new Event('toggleSidebar');
    },
    beforeDestroy() {
        this.toggleSidebarevent = null;
    }
}
</script>

