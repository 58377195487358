<template>
    <p class="version">
        Version {{appVersion}} -
        <a :href="changeLogUri" target="_blank">Changelog <b-icon icon="box-arrow-up-right"/></a> |
        <router-link to="profile">Privacy e condizioni di utilizzo</router-link>
    </p>
</template>

<script>
import {version} from '@/../package.json'

export default {
    name: "Version",
    data() {
        return {
            appVersion: version,
            changeLogUri: null
        }
    },
    mounted() {
        this.changeLogUri = this.$router.options.base + 'changelog.txt'
    }
}
</script>

<style scoped>

</style>
