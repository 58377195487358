import axios from "axios";
import {store} from "../components/store.js"

const loginEvent = new Event('onLoggedIn');
const logoutEvent = new Event('onLoggedOut');
const httpErrorEvent = new Event('onHttpError');
const httpRequestEvent = new Event('onHttpRequest');
const httpResponseEvent = new Event('onHttpResponse');

let apiBaseURL = '';
if (process.env.VUE_APP_ENV == 'production') {
    apiBaseURL = 'https://edugest.it:3002/api/'
} else if (process.env.VUE_APP_ENV == 'staging') {
    apiBaseURL = 'https://dev.bradipon.it:3002/api/'
} else { // development localhost
    apiBaseURL = 'http://localhost:3001/api/'
    //apiBaseURL = 'http://192.168.1.204:3001/api/'
}

const http = axios.create({
    baseURL: apiBaseURL,
    headers: {
        "Content-type": "application/json",
    }
});


http.interceptors.request.use(config => {
    httpRequestEvent.url = config.url;
    document.dispatchEvent(httpRequestEvent);
    const authUser = JSON.parse(localStorage.getItem('authUser'));
    if (authUser) {
        if (authUser.token) {
            config.headers["x-access-token"] = authUser.token;
        }
        if (authUser.data.roles.includes('admin') && store.state.schoolId > 0) {
            config.headers["x-school-id"] = store.state.schoolId;
        }
    }
    store.state.loading = true;
    //console.log(store.state.schoolId);
    return config
})


http.interceptors.response.use(response => {
    httpResponseEvent.url = response.config.url;
    document.dispatchEvent(httpResponseEvent);
    store.state.loading = false;
    return response;
}, error => {
    store.state.loading = false;
    switch (error.response.status) {
        case 403:
            document.dispatchEvent(logoutEvent);
            break;
        default:
            httpErrorEvent.errno = error.response.status;
            if (error.response.data.message) {
                httpErrorEvent.message = error.response.data.message;
            } else {
                httpErrorEvent.message = error.response.statusText;
            }
            if (error.response.data.code) {
                httpErrorEvent.code = error.response.data.code;
            }
            if (error.response.data.errno) {
                httpErrorEvent.errno = error.response.data.errno;
            }
            document.dispatchEvent(httpErrorEvent);
            break;
    }
    return Promise.reject(error);
});

export default http;
