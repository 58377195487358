import http from "../../helpers/http-common";

class AuthDataService {
    login(data) {
        return http.post("/auth/login/", data);
    }

    logout() {
        return http.get('/auth/logout/');
    }

    me() {
        return http.get('/auth/me/');
    }

    register(data) {
        return http.post("/auth/register/", data);
    }

    resetPassword(data) {
        return http.put("/auth/reset-password/", data);
    }

    isValidNewPassToken(token) {
        return http.get(`/auth/valid-newpass-token/?token=${token}`);
    }

    changePassword(data) {
        return http.post("/auth/change-password/", data);
    }

    switchSchool(id) {
        return http.get(`/auth/switch-school/${id}`);
    }

    keepAlive() {
        return http.get(`/auth/keepalive/`);
    }
    
}

export default new AuthDataService();
