<template>
    <div style="margin-top: 2vh;">
        <p class=" text-center"> <img alt="logo" src="../../assets/logo.png" height="160"></p>
        <hr>
        <router-view/>
        <br>
        <!--
        <div class="fixed-bottom container-fluid text-center">
            <version />
        </div>
        -->
    </div>
</template>

<script>
import Version from "@/components/layout/Version";

export default {
    name: "front",

}
</script>

<style>
.card-login {
    width: 370px;
}
@media screen and (min-width: 650px) {
    .front-top-content {
        margin: 0 auto;
        max-width: 550px;
    }
}
@media screen and (max-width: 600px){
    .card-login {
        width: auto;
        background-color: transparent;
        border: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
}
</style>