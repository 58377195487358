<template>
    <div>
        <router-view/>
    </div>
</template>

<script>
//import '@/assets/styles/admin.css'

export default {
    name: "Print",
}
</script>
